import { fallback, http } from 'viem'
import { arbitrum, blast, localhost, lyra, mainnet, manta, mantle } from 'viem/chains'

import { kinto } from '../components/kinto'

const blastApiKey = process.env.NEXT_PUBLIC_BLAST_API_KEY
const groveRpc = process.env.NEXT_PUBLIC_GROVE_RPC
const alchemyKey = process.env.NEXT_PUBLIC_ALCHEMY_API_KEY_MAINNET
// mainnet, mantle, arbitrum, blast, kinto, lyra, manta
export const TRANSPORTS = {
  [mainnet.id]: fallback([
    http('https://eth-mainnet.g.alchemy.com/v2/' + alchemyKey),
    http('https://eth-mainnet.blastapi.io/' + blastApiKey),
    http('https://rpc.ankr.com/eth'),
    http(groveRpc),
    http('https://cloudflare-eth.com'),
  ]),
  [localhost.id]: http('http://localhost:8545'),
  [arbitrum.id]: fallback([
    http('https://arbitrum-one.blastapi.io/' + blastApiKey),
    http('https://arb1.arbitrum.io/rpc'),
  ]),
  [mantle.id]: fallback([http('https://mantle-mainnet.blastapi.io/' + blastApiKey), http('https://rpc.mantle.xyz')]),
  [blast.id]: fallback([http('https://blastl2-mainnet.blastapi.io/' + blastApiKey), http('https://rpc.blast.io')]),
  [kinto.id]: http('https://rpc.kinto-rpc.com'),
  [lyra.id]: http('https://rpc.lyra.finance'),
  [manta.id]: http('https://pacific-rpc.manta.network/http'),
}
